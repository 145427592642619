:root {
    --colWork: #a66cff;
    --colTravel: #cff500;
    
}

*{
    box-sizing: border-box;
    user-select: none;
}
.pageCont{
    position: relative;
    width: 100%!important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    padding: 40px;
    will-change: auto;
    transition:  all .2s ease;
    box-sizing: border-box;
    row-gap: 6px;
    background-color: rgb(177, 177, 177);
    
}
.wholeYear{
    position: relative;
    width: 100%;
    /* height: 100%; */
    border-radius: 12px;
    overflow: hidden;
    
}
.dataDisplay{
    width: 100%;
    height: 100%;
    display: block;
    flex-direction: column;
    padding: 0px;
    will-change: width;
    transition: width 1.5s ease;
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 6px;
}

.monthGroup{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* padding:    10px; */
    will-change: width;
    transition: width 1.5s ease;
    column-gap: 10px;
    align-items: stretch;
    z-index: 1;
    border-radius: 12px;
    transform: translateY(0%);
    will-change: all;
    transition: all 1.3s ease;
}
.dataTotals{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: gray;
    padding:    10px;
    column-gap: 10px;
    align-items: stretch;
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;
    /* transform: translateY(50%); */
    will-change: all;
    transition: all 1.5s ease;

}
.dataYears{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding:    10px;
    column-gap: 10px;
    align-items: stretch;
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;
    /* transform: translateY(50%); */
    will-change: all;
    transition: all 1.5s ease;

}
.month{
    width: 8.33%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    border-radius: 10px;
    overflow: hidden;
    will-change: all;
    transition: all 0.5s ease;
    /* gap: 2px; */
    /* padding: 5px; */
    background-color: #ffccd4;
    /* margin-bottom: 10px; */
    
}

.dayDiv{
    height: 20px;
    font-size: 0.75em;
    font-weight: 700;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow:1;
    border-radius: 6px;
    column-gap: 6px;
    will-change: auto;
    transition: auto 1s ease;
    overflow: hidden;
    border-bottom: 1px dashed #ffffff88;
}
.dayNumber{
    font-size: 0.9em;
    font-weight: 900!important;
    width: 20px;
    /* padding: 0px 4px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.dayInfo{
    font-size: 0.98em;
    font-weight: 900!important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity: 0;
    will-change: all;
    transition: all 0.2s ease;
    transform: translateX(50%);
}
.dayDiv:hover .dayInfo{
    opacity: 1;
    transform: translateX(0%);
}
.monthLable{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--colWork);
}
.pastMonth{
    background-color: pink;
}
.futureMonth{
   color: #ffffff;
}
.bottomGroup{
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px 10px;
    margin-bottom: 15px;
    background-color: black;
}
.yearTitle{
    font-size: 6em;
    font-weight: 700;
    color: var(--colTravel);
}
.buttonsGroup{
    height: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 4px    ;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: pink;
    border-radius: 6px;
    overflow: hidden;
    will-change: all;
    transition: width 0.25s ease;
    transition: background-color .1s  linear;
    text-transform: uppercase;
}
.button:hover{
    background-color: white;
}
.button:active{
    background-color: var(--colTravel);
}
.buttonsHorizGroup{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 4px;
}
.weekendSwitch{
    width: 120px;
    height: 30%;
    font-size: 1.2em;
    font-weight: 700;
}
.yearButton{
    width: 50px;
    font-size: 3em;
    font-weight: 700;
    height: 100%;
    will-change: all;
    transition: all 0.5s ease;
    overflow: hidden;
}
.info{
    font-size: 1.35em;
    font-weight: 700;
    color: var(--colTravel);
    will-change: all;
    transition: all 0.5s ease;
    padding: 0px 20px;
    text-align: center;
}
.infoNumber{
    font-size: 1.2em;
    font-weight: 700;
    color: white;
}
.progressBarCont{
    width: 100%;
    height: 20px;
    border-radius: 12px;
    background-color: var(--colWork);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    border: 5px solid black;
}
.progressBar{
    width: 0%;
    height: 10px;
    /* border-radius: 8px; */
    transform: translateX(-100%);
    background-color: var(--colTravel);
    will-change: all ;
    transition: all 3s ease;
}

.daysGroup{
    height: 500;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    will-change: all;
    transition: all 1s ease;
}
.monthColumn{
    width: 8.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    overflow: hidden;
    will-change: all;
    transition: all 0.5s ease;
    gap: 4px;
    padding: 5px;
    background-color: pink;
    will-change: all;
    transition: all 2s ease;
}

.totalMonthCont{
    height: 99%;
    border-radius: 10px;
    /* overflow: hidden; */
    will-change: all;
    transition: all 2s ease;

}

.totalMonth{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    color: white;
    font-size: 1.5em;
    font-weight: 700;
    border-radius: 10px;
    overflow: hidden;
    will-change: height;
    transition: height 2s ease;
    /* padding: 10px 0px; */

}
.totalSubText{
    font-size: 0.5em;
    text-transform: uppercase;
    font-weight: 400;
}

.monthRow{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    overflow: hidden;
    will-change: all;
    transition: all 0.5s ease;
    row-gap: 4px;
    padding: 5px;
    background-color: pink;
    will-change: all;
    transition: all 2s ease;
}

.yearsList{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    overflow: hidden;
    will-change: all;
    transition: all 0.5s ease;
    row-gap: 10px;
    padding: 10px;

    will-change: all;
    transition: all 2s ease;
}
.yearsMonthRow{
    width: 86%;

    display: flex;
    flex-direction: row;
    border-radius: 10px;
}
.yearsSummary{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.5em;
    font-weight: 700;


}