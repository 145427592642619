.alertBox {
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%) ;
  z-index: 9999;
  color: #000000;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom:10px;
  padding-right: 80px;
  padding-left: 80px;
  -webkit-box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
  box-shadow: 20 2rem 4rem rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  transition: all 0.3s ease;
  opacity: 0;
}
.alertDimmed {
  color: #00000088;
  text-transform: uppercase;
  font-weight: 300;


}
