:root {
  --colWork: #a66cff;
  --colTravel: #cff500;
}
*{

  box-sizing: border-box;
  user-select: none;
}
.objDbPage{
  background-color: white;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.objDbCont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding-top: 20px;
  row-gap: 15px;

}

.heroImg{
  width: 90%;

  border-radius: 12px;
}
.infoCont{
  width: 90%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
}
.objName{
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
.objBy{
  font-size: 15px;
  color: #777777;
}
.objDesc{
  font-size: 20px;

}

.objSubtitle{
  font-size: 22px;
}

.objMeta{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.searchSection{

  display: flex;
  justify-content: center;
  width: 90%;
  padding: 50px 0px;
  border-top: 1px solid #888888;
  column-gap: 10px;
}
.input{
  background-color: #dddddd;

  border: 1px black solid;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  padding: 6px;

}

.buttonCataloge{
  background-color: var(--colTravel);
  border: 1px black solid;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  padding: 6px;
}