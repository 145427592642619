
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;400&display=swap'); */

*{
  box-sizing: border-box;
  font-family: 'Source Code Pro', monospace;
    /* font-family: 'IBM Plex Mono', monospace; */
    font-weight: 300;
}
html,
body {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a{
  text-decoration: none;
}
a:link {
  color: white
}
a:visited{
  text-decoration: none;
  color: white;
}
.redirecting {
  width: 100%;
  color: '#8888888';
  text-align: center;
}

h1 {
  font-size: 50px;
}
