*{
box-sizing: border-box;
}
.rndrPage{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 6%;
font-weight: 700;
}

.rndrInputs{
width: 900px;
height:150px;    
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
column-gap: 12px;
margin-bottom: 50px;
/* background-color: red; */
}
.inputGroup{
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center ;
    /* border: 1px solid white; */
    padding: 8px;
    background-color: blue;
    border-radius: 20px;
}
.inputGroupFrames{
    height: 100%;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center ;
}
.inputLabel{
    font-size: 1.2em;
    color: white;
    text-transform: uppercase;
    }
.inputForm{
    position: relative;
    width: 100%;
    border: none;
    background-color: transparent;
    color: white;
    outline-width: 0; 
    font-size: 4em;
    text-align: center;
    /* background-color: gold; */
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    outline-width: 0;
  }
  ::placeholder {
    color: #ffffff;
    opacity: 0.3; /* Firefox */
    font-size: 0.8em;
  }
.rndrResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rndrTime{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
column-gap: 20px;
}
.underline{
    width: 100%;
    height: 10px;
    background-color: blue;
    border-radius: 5px;
}
.resultNumber{
color: blue;
font-size: 8em;
}
.resultUnit{
font-size: 0.5em;
/* color: #0c073f; */
}

.footerSignUp{
    display: none;
    margin-top: 20px;
    color: #777777;
}
.footerSignDown{
    display: block;
    margin-top: 50px;
    color: #777777;
}
.message{
    position: absolute;
    top: 4%;
    left: 50%;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 1.5em;
    background-color: white;
    color: orangered;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateX(-50%);
    will-change: opacity;
    transition: opacity 0.2s ease;
}
@media only screen and (max-width: 600px) {
    .rndrInputs{

        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        margin-bottom: 50px;
        padding-top: 30px;
    }
    .resultNumber{

        font-size: 4em;
    }
    .rndrPage{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        padding: 5%;
        font-weight: 700;
    }
    .footerSignUp{
        display: block;

    }
    .footerSignDown{
        display: none;

    }
    .message{
        position: absolute;
        width: 100%;
        top: 0%;
        left: 50%;
        padding: 10px 10px;
        border-radius: 4px;
        font-size: 1em;
        color: orangered;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: translateX(-50%);
        will-change: opacity;
        transition: opacity 0.2s ease;
    }

  }