*{
    box-sizing: border-box;
}

body{
    /* background-color: pink; */
    color: black;
    box-sizing: border-box;


}
.horizGroup{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.pageHdri{
    position: relative;
    width: 100%!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-left: 0px solid #ee0000cc;
    border-right: 0px solid #ee0000cc;
    will-change: auto;
    transition:  all .2s ease;
}

.pageHeader{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 15px;
    border: 1px solid blue;
    /* background-color: #bbbbbb; */
  
} 
.pageTitle{
    /* font-family: 'Source Code Pro', monospace; */
    font-size: 40px;
    font-weight: 700;
    color: blue;

}

.hdriSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
   
}
.hdriCard{
    min-width: 320px;
    height: 180px;
   flex-grow: 1;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-shrink: 2;
    padding: 5px;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-size: cover;
    background-position: center;

}
.hdriCardInfo {
    font-size: 16px;
    background-color: #ffffff55;
    padding:1px 10px;
    border-radius: 6px;
    will-change: 'background-color';
    transition: 0.2s;
}

.hdriCard:hover .hdriCardInfo {
    color: #ffffff;
    background-color: #0000ff!important;
}



