*{
    box-sizing: border-box;
}
.quotePage{
    /* background-color: pink; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
}
.quote{
    width: 100%;
    font-size: 6em;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    color: blue;
  
  }
  
  .quoteSource{
    font-size: 2em;
    color: blue;
    background-color: #eeeeee44;
    padding: 2px 8px;
    border-radius: 6px;
  }